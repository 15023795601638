import api from './api';
import type { AdvertisementType } from './gig';

export type KmongAdvertisementGroupType = 'CPC' | 'CPT';

export interface KmongAnalyticsGigProps {
  gig_card_uuid?: string;
  kmong_ad_id?: number;
  kmong_ad_group_type?: KmongAdvertisementGroupType;
}

export interface KmongAd {
  adId: number | null;
  adGroupType: KmongAdvertisementGroupType | null;
}

export interface TempKmongAnalyticsGigProps {
  gigCardUuid?: string;
  kmongAd: KmongAd | null;
}

export interface SendUserParams {
  kmongIdentifyEventId: number;
  userId: number | null;
  kmongSessionId: string | null;
  sessionId: number | null;
  deviceId: string;
  userAgent: string | null;
  sentAt: string;
}

export interface SendUserResponse {
  kmongSessionId: string;
}

const sendUserSession = async (params: SendUserParams) => {
  const { data } = await api.post<SendUserResponse>('/api/next/analytics-app/user/v1/session', params);

  return data;
};

export interface UserInfo {
  userId: number | null;
  kmongSessionId: string | null;
  sessionId: number | null;
}

export type KmongAnalyticsEventType = 'PAGE_VIEW' | 'SCROLL';

export interface EventInfo {
  eventId: number;
  impressionType: string;
  eventType: KmongAnalyticsEventType;
}

export interface LocationInfo {
  pagePath: string;
  queryString: string;
  isFiltered: boolean;
  categoryDepth: number;
  rootCategoryId: number;
  subCategoryId: number | null;
  thirdCategoryId: number | null;
}

export interface DeviceInfo {
  deviceId: string;
  userAgent: string | null;
  appVersion: string | null;
}

export interface KmongAnalyticsGig {
  gigId: number;
  gigCardUuid: string;
  sellerUserId: number;
  rootCategoryId: number;
  subCategoryId: number | null;
  thirdCategoryId: number | null;
  kmongAdId: number | null;
  kmongAdType: AdvertisementType | null;
  kmongAdGroup: KmongAdvertisementGroupType | null;
  position: number;
  row: number;
  column: number;
}

export interface SendGigImpressionParams {
  userInfo: UserInfo;
  deviceInfo: DeviceInfo;
  locationInfo: LocationInfo;
  eventInfo: EventInfo;
  gigs: KmongAnalyticsGig[];
  sentAt: string;
}

export interface SendGigImpressionResponse {
  impressionUuid: string;
}

const sendGigImpression = async (params: SendGigImpressionParams) => {
  const { data } = await api.post<SendGigImpressionResponse>('/api/next/analytics-app/gig/v1/gigs', params);

  return data;
};

export interface SearchLocationInfo {
  pagePath: string;
  queryString: string;
  searchKeywordUuid: string | null;
  searchKeyword: string;
  isFiltered: boolean;
  categoryDepth: number | null;
  rootCategoryId: number | null;
  subCategoryId: number | null;
  thirdCategoryId: number | null;
}

export interface SendSearchImpressionParams {
  userInfo: UserInfo;
  deviceInfo: DeviceInfo;
  locationInfo: SearchLocationInfo;
  eventInfo: EventInfo;
  gigs: KmongAnalyticsGig[];
  sentAt: string;
}

const sendSearchImpression = async (params: SendSearchImpressionParams) => {
  const { data } = await api.post<SendGigImpressionResponse>('/api/next/analytics-app/gig/v1/search-result/gigs', params);

  return data;
};

export interface SendGigClickParams {
  userInfo: UserInfo;
  deviceInfo: DeviceInfo;
  locationInfo: LocationInfo;
  eventInfo: EventInfo;
  gigCardInfo: KmongAnalyticsGig;
  sentAt: string;
}

export interface SendGigClickResponse {
  gigCardClickUuid: string;
}

const sendGigClick = async (gigId: number, params: SendGigClickParams) => {
  const { data } = await api.post<SendGigClickResponse>(`/api/next/analytics-app/gig/v1/gigs/${gigId}`, params);

  return data;
};

export interface SendSearchClickParams {
  userInfo: UserInfo;
  deviceInfo: DeviceInfo;
  locationInfo: SearchLocationInfo;
  eventInfo: EventInfo;
  gigCardInfo: KmongAnalyticsGig;
  sentAt: string;
}

const sendSearchClick = async (gigId: number, params: SendSearchClickParams) => {
  const { data } = await api.post<SendGigClickResponse>(`/api/next/analytics-app/gig/v1/search-result/gigs/${gigId}`, params);

  return data;
};

export const kmongAnalyticsApi = {
  sendUserSession,
  sendGigImpression,
  sendSearchImpression,
  sendGigClick,
  sendSearchClick,
};
