import api from './api';
import type { Gig } from './gig';

export type CaptionModules = HeroImageType
| HeroTemplateImageType
| NavigationType;

export type ItemModules = BannerImageType
| GigType
| GigFiveCutType
| GigSlideType
| GigFocusType
| VideoType
| CouponType
| CouponImageType
| TextFieldType;

export type LinkType = 'CATEGORY' | 'SEARCH' | 'MD_PICK' | 'GIG_DETAIL' | 'WEB_VIEW' | 'EXTERNAL_LINK';

export interface OgDataType {
  title: string;
  description: string;
  image: string;
}

export enum CaptionType {
  HERO_IMAGE = 'HERO_IMAGE',
  HERO_TEMPLATE_IMAGE = 'HERO_TEMPLATE_IMAGE',
  NAVIGATION_BAR = 'NAVIGATION_BAR',
}

export enum ItemType {
  GIG = 'GIG',
  GIG_FIVE_CUT = 'GIG_FIVE_CUT',
  GIG_SLIDE = 'GIG_SLIDE',
  GIG_FOCUS = 'GIG_FOCUS',
  BANNER_IMAGE = 'BANNER_IMAGE',
  VIDEO = 'VIDEO',
  COUPON = 'COUPON',
  COUPON_IMAGE = 'COUPON_IMAGE',
  TEXT_FILED = 'TEXT_FILED'
}

export interface Navigation {
  id: number;
  title: string;
}

export interface Link {
  type: LinkType;
  value: string;
  url: string;
}

export interface Coupon {
  title: string;
  code: string | null;
  type: 'PERCENT' | 'AMOUNT';
  value: number;
}

export interface ModuleBase {
  id: number;
}

export interface ModuleBaseWithImageMeta extends ModuleBase {
  mobile_image_width: number;
  mobile_image_height: number;
  desktop_image_width: number;
  desktop_image_height: number;
}

export interface GigModuleBase extends ModuleBase {
  gigs: Gig[];
  title: string | null;
  sub_title: string;
  is_random: boolean;
  is_ratings_shown: boolean;
  button: string;
  link?: Link;
}

export interface HeroImageType extends ModuleBaseWithImageMeta {
  type: CaptionType.HERO_IMAGE;
  image: string;
}

export interface HeroTemplateImageType extends ModuleBaseWithImageMeta {
  type: CaptionType.HERO_TEMPLATE_IMAGE;
  image: string;
  title: string;
  sub_title: string | null;
  button: string | null;
  background_color: string;
  text_color: string | null;
  link?: Link;
}

export interface NavigationType extends ModuleBase {
  type: CaptionType.NAVIGATION_BAR;
  navigation_bar: Navigation[];
}

export interface TextFieldType extends ModuleBase {
  type: ItemType.TEXT_FILED;
  title: string;
  description: string;
}

export interface BannerImageType extends ModuleBaseWithImageMeta {
  type: ItemType.BANNER_IMAGE;
  image: string;
  vertical_margin: boolean;
  link?: Link;
}

export interface VideoType extends ModuleBase {
  type: ItemType.VIDEO;
  url: string;
  title: string | null;
  sub_title: string | null;
}

export interface CouponType extends ModuleBase {
  type: ItemType.COUPON;
  coupon: Coupon;
  instructions: string;
  title: string;
  sub_title: string;
}

export interface CouponImageType extends ModuleBaseWithImageMeta {
  type: ItemType.COUPON_IMAGE;
  coupon: Coupon;
  image: string;
  vertical_margin: boolean;
}

export interface GigType extends GigModuleBase {
  type: ItemType.GIG;
}

export interface GigFiveCutType extends GigModuleBase {
  type: ItemType.GIG_FIVE_CUT;
}

export interface GigFocusType extends GigModuleBase {
  type: ItemType.GIG_FOCUS;
}

export interface GigSlideType extends GigModuleBase {
  type: ItemType.GIG_SLIDE;
}

export interface GetMdPickDetailResponse {
  title: string;
  og_data: OgDataType;
  captions: CaptionModules[];
  items: ItemModules[];
}

const getMdPickDetail = async (id: number, customUA?: string) => {
  const { data } = await api.get<GetMdPickDetailResponse>(`/api/v5/md-pick/${id}`, customUA ? {
    headers: {
      'User-Agent': customUA,
    },
  } : undefined);

  return data;
};

export const mdPickApi = {
  getMdPickDetail,
};
