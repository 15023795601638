import type { DesignTypeBase } from './types/gig-modules.type';

export interface ButtonGroupDesignType extends DesignTypeBase {
  design_type: 'BUTTON_GROUP';
  inbox: Inbox;
  order: Order;
  bookmark: Bookmark;
  on_vacation: boolean;
  vacation: Vacation | null;
}

interface Inbox {
  label: string;
  is_my_gig: boolean;
  is_hidden: boolean;
  seller_USERID: number;
  title: string;
  price: number;
  image: string;
  category: Category;
  is_prime: boolean;
}

interface Category {
  root_category: RootCategory;
  sub_category: SubCategory;
  third_category: ThirdCategory;
}

interface RootCategory {
  id: number;
  name: string;
}

interface SubCategory {
  id: number;
  name: string;
}

interface ThirdCategory {
  id: number;
  name: string;
}

export enum GigOrderAction {
  PACKAGE = 'PACKAGE',
  PRINTING_SHOP = 'PRINTING_SHOP',
  INQUIRY = 'INQUIRY',
  CUSTOM_OPTION = 'CUSTOM_OPTION',
}

interface Order {
  label: string;
  is_my_gig: boolean;
  is_hidden: boolean;
  action: GigOrderAction;
  is_prime: boolean;
  category_id: number;
}

interface Bookmark {
  label: string;
  is_bookmark: boolean;
  bookmark_count: number;
  is_my_gig: boolean;
  is_hidden: boolean;
}

interface Vacation {
  reason: string;
  label: string;
  expired_at: string;
  is_my_gig: boolean;
}
