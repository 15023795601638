import api from '../../api';

interface PurchasedProduct {
  title: string;
  link: string;
}

interface CheckoutDetails {
  title: string;
  expired_at: string;
}

interface ChargedCheckoutDetails {
  title: string;
  expired_at: string;
}

export interface CashHistory {
  id: number;
  type: string;
  amount: string;
  OID: number | null;
  title: string;
  purchased_product: PurchasedProduct | null;
  checkout: CheckoutDetails | null;
  charged_checkout: ChargedCheckoutDetails | null;
  created_at: string;
}

interface CashHistoryResponse {
  total: number;
  previous_page_link: string | null;
  next_page_link: string | null;
  last_page: number;
  cash_histories: CashHistory[];
}

export interface CashHistoryParams {
  page: number;
  start_date: string;
  end_date: string;
  type: string;
  per_page: number;
}

const getCashHistory = async (params: CashHistoryParams) => {
  const { data } = await api.get<CashHistoryResponse>('/api/v5/my-kmong/BUYER/cash-histories', { params });

  return data;
};

interface Summary {
  amount: number;
  title: string;
}

export interface CashHistoriesInfo {
  expected_expired_cash_amount: number;
  expected_expired_charged_funds: {
    amount: number;
    title: string;
  };
  summaries: Summary[];
}

const getCashHistoriesInfo = async () => {
  const { data } = await api.get<CashHistoriesInfo>('/api/v5/my-kmong/BUYER/cash-histories.info');

  return data;
};

interface RefundRequest {
  amount: number;
  id: number;
  requested_at: string;
  title: string;
}

const getCashHistoriesRefundRequest = async () => {
  const { data } = await api.get<RefundRequest>('/api/v5/my-kmong/BUYER/cash-histories/refund-request');

  return data;
};

const cancelRefundRequest = async (id: number) => {
  const { data } = await api.delete(`/payments/funds/refund/${id}`);

  return data;
};

export interface RefundAccountDetail {
  userRefundAccountId: number;
  bankName: string;
  bankCode: string;
  depositor: string;
  accountNumber: string;
  accountType: string;
}

interface UserRefundAccountResponse {
  userRefundAccountDetail: RefundAccountDetail | null;
}

const getUserRefundAccount = async () => {
  const { data } = await api.get<UserRefundAccountResponse>('/api/next/order-app/payment/v1/payments/buyer/refund/user-refund-accounts');

  return data;
};

interface RemainingCashResponse {
  amount: number;
}

const getRemainingCash = async () => {
  const { data } = await api.get<RemainingCashResponse>('/api/next/order-app/payment/v1/payments/buyer/funds/cash/amount/remaining');

  return data;
};

interface RequestRefundResponse {
  refundRequestId: number;
}

const requestRefund = async () => {
  const { data } = await api.post<RequestRefundResponse>('/api/payment/buyer/refund/v1/funds/request');

  return data;
};

export const cashApi = {
  getCashHistory,
  getCashHistoriesInfo,
  getCashHistoriesRefundRequest,
  cancelRefundRequest,
  getUserRefundAccount,
  getRemainingCash,
  requestRefund,
};
