import api from '../api';

export interface AvailableGigApplicant {
  applicantId: number;
}

export interface RelatedSearchKeyword {
  keyword: string;
  searchKeywordUuid: string;
  bidSuggestedAmount: number;
}

export interface AvailableGig {
  currentCategoryId: number;
  title: string | null;
  gigId: number;
  images: string[];
  applicant: AvailableGigApplicant | null;
}

export interface GetAvailableGigsResponse {
  gigs: AvailableGig[];
}

const getAvailableGigs = async () => {
  const { data } = await api.get<GetAvailableGigsResponse>('/api/next/gig-app/gig/v1/kmong-ad/click/click-up/available-gigs');

  return data;
};

export enum CurrentDisplayStatusTypeLabel {
  READY = '대기 중',
  DISPLAYABLE = '노출 가능',
  DISPLAYING = '노출 중',
  STOPPED = '중지',
}

export type CurrentDisplayStatusType = keyof typeof CurrentDisplayStatusTypeLabel

export enum CurrentDisplayStatusReasonTypeLabel {
  TURNED_OFF = '광고 OFF',
  NEEDS_FUNDS = '잔액 부족',
  EXCEED_BUDGET = '일 예산 도달',
}

export type CurrentDisplayStatusReasonType = keyof typeof CurrentDisplayStatusReasonTypeLabel

export interface CategoryInfo {
  rootCategoryId: number;
  rootCategoryName: string;
  subCategoryId: number | null;
  subCategoryName: string | null;
  thirdCategoryId: number | null;
  thirdCategoryName: string | null;
}

interface GigDetail {
  gigId: number | null;
  title: string | null;
  images: string[] | null;
  category: CategoryInfo;
}

export interface GigClickApplicant {
  id: number;
  sellerUserId: number;
  gigId: number;
  gig: GigDetail;
  currentDisplayingCategoryIds: number[];
  adPolicyId: number;
  bidAmount: number;
  budgetAmount: number | null;
  currentDisplayStatusType: CurrentDisplayStatusType;
  currentDisplayStatusReasonType: CurrentDisplayStatusReasonType | null;
  isOn: boolean;
  expectedClosedDateTime: string | null;
  createdDateTime: string;
  updatedDateTime: string;
}

export interface GetGigClickApplicantsParams {
  searchKindType: 'GIG_ID' | null;
  keyword: string | null;
}

export interface CurrentDisplayingSearchKeyword {
  searchKeyword: string;
  searchKeywordUuid: string;
}

export interface GigClickApplicantItem extends GigClickApplicant {
  currentDisplayingSearchKeywords: CurrentDisplayingSearchKeyword[];
}

export interface GetGigClickApplicantsResponse {
  items: GigClickApplicantItem[];
}

const getGigClickApplicants = async (params?: GetGigClickApplicantsParams) => {
  const { data } = await api.get<GetGigClickApplicantsResponse>('/api/next/order-app/kmong-ad/v1/gig/click/click-up/applicants', { params });

  return data;
};

interface SelectedSearchKeyword {
  searchKeywordUuid: string;
}

export interface GigClickApplicantDetailResponse extends Omit<GigClickApplicant, 'gig' | 'currentDisplayingCategoryIds' | 'currentDisplayStatusType'> {
  selectedSearchKeywords: SelectedSearchKeyword[];
}

const getGigClickApplicantDetail = async (applicantId: number) => {
  const { data } = await api.get<GigClickApplicantDetailResponse>(`/api/next/order-app/kmong-ad/v1/gig/click/click-up/applicants/${applicantId}`);

  return data;
};

export interface PostGigClickApplicantData {
  expectedClosedDate: string | null;
  budgetAmount: number | null;
  bidAmount: number;
  gigId: number;
  searchKeywordUuids: string[];
  relatedSearchKeywords?: RelatedSearchKeyword[];
}

const postGigClickApplicant = async (data: PostGigClickApplicantData) => {
  await api.post('/api/next/order-app/kmong-ad/v1/gig/click/click-up/applicants', data);
};

export interface PutGigClickApplicantData extends Omit<PostGigClickApplicantData, 'gigId'>{
  applicantId: number;
}

const putGigClickApplicant = async ({ applicantId, ...data }: PutGigClickApplicantData) => {
  await api.put(`/api/next/order-app/kmong-ad/v1/gig/click/click-up/applicants/${applicantId}`, data);
};

const putDisplayStatusOn = async (applicantId: number) => {
  await api.put(`/api/next/order-app/kmong-ad/v1/gig/click/click-up/applicants/${applicantId}/action/on`);
};

const putDisplayStatusOff = async (applicantId: number) => {
  await api.put(`/api/next/order-app/kmong-ad/v1/gig/click/click-up/applicants/${applicantId}/action/off`);
};

export interface GetClickReportsParams {
  startedDate: string;
  endedDate: string;
}

export interface ClickReportSummary {
  totalImpressionCount: number;
  totalValidClickCount: number;
  averageClickAmount: number;
  totalDeductedClickAmount: number;
}

export interface ClickReportItem {
  applicantId: number;
  userId: number;
  gigId: number;
  clickUpReport: ClickReportSummary;
}

export interface GetClickReportsResponse {
  summary: ClickReportSummary;
  items: ClickReportItem[];
}

const getClickReports = async (params: GetClickReportsParams) => {
  const { data } = await api.get<GetClickReportsResponse>('/api/next/order-app/kmong-ad/v1/gig/click/click-up/reports', { params });

  return data;
};

export interface GetSuggestedBidAmountResponse extends CategoryInfo {
  rootCategoryAmount: number;
  subCategoryAmount: number;
  thirdCategoryAmount: number;
}

const getSuggestedBidAmount = async (categoryId: number) => {
  const { data } = await api.get<GetSuggestedBidAmountResponse>(`/api/next/order-app/kmong-ad/v1/gig/click/category/categories/${categoryId}/suggested-bid-amounts`);

  return data;
};

export interface GetRelatedSearchKeywordsResponse {
  relatedSearchKeywords: RelatedSearchKeyword[];
}

const getRelatedSearchKeywords = async (gigId: number) => {
  const { data } = await api.get<GetRelatedSearchKeywordsResponse>(`/api/next/order-app/kmong-ad/v1/gig/click/search/gigs/${gigId}/suggested-bid-amounts`);

  return data;
};

export const clickUpApi = {
  getAvailableGigs,
  getGigClickApplicants,
  getGigClickApplicantDetail,
  postGigClickApplicant,
  putGigClickApplicant,
  putDisplayStatusOn,
  putDisplayStatusOff,
  getClickReports,
  getSuggestedBidAmount,
  getRelatedSearchKeywords,
};
