import api from '../api';

export enum InboxActionTypeEnum {
  MESSAGE = 'MESSAGE',
  REQUEST = 'REQUEST',
  RELATED_ORDER = 'RELATED_ORDER',
  RELATED_PORTFOLIO = 'RELATED_PORTFOLIO',
  RELATED_GIG = 'RELATED_GIG',
  /**
   * @deprecated 데이터 없음
   * @link https://kmongteam.atlassian.net/wiki/spaces/DEV/pages/3020131898
   */
  RELATED_CUSTOM_PROJECT_ORDER = 'RELATED_CUSTOM_PROJECT_ORDER', // 맞춤견적 거래메세지
  /**
   * @deprecated
   * @link https://kmongteam.atlassian.net/wiki/spaces/DEV/pages/3020131898
   */
  RELATED_CUSTOM_PROJECT = 'RELATED_CUSTOM_PROJECT',
  REQUEST_CUSTOM_PROJECT = 'REQUEST_CUSTOM_PROJECT', // 맞춤견적 다시 보내기
  /**
   * @deprecated 2023-10-16 11:38:02
   * @link https://kmongteam.atlassian.net/wiki/spaces/DEV/pages/3020131898
   */
  INQUIRY = 'INQUIRY',
  /**
   * @deprecated 2021-07-12 10:49:27
   * @link https://kmongteam.atlassian.net/wiki/spaces/DEV/pages/3020131898
   */
  RECOMMEND_INQUIRY = 'RECOMMEND_INQUIRY', // 전문가 추천 문의
  USER_SCHEDULE_CREATED = 'USER_SCHEDULE_CREATED', // 일정 확정
  USER_SCHEDULE_UPDATED = 'USER_SCHEDULE_UPDATED', // 일정 변경
  USER_SCHEDULE_CANCELED = 'USER_SCHEDULE_CANCELED', // 일정 취소
}

export enum InboxActionStatusEnum {
  PENDING = 0,
  REJECTED = -1,
  ACCEPTED = 1,
}

export type InboxMessage = {
  MID: number;
  message: string | null;
  action_status: InboxActionStatusEnum;
  files: InboxFile[] | null;
  is_unread: boolean;
  sent_at: string | null;
  sent_at_date: string | null;
  sent_at_time: string | null;
  inbox_request: InboxRequest | null;
  sender: InboxSender;
  is_mine: boolean;
  socket_id?: string | null;
  inbox_group_id?: number;
  warning_messages: InboxWarningMessage;
  isEncryptedMessage: boolean;
} & InboxMessageExtraData;

type InboxMessageExtraData =
  | InboxMessageExtraDataBase
  | InboxMessageRelatedExtraData
  | InboxMessageRequestExtraData;

export type InboxMessageExtraDataBase =
  | { action: InboxActionTypeEnum.MESSAGE; extra_data: null | object }
  | { action: InboxActionTypeEnum.USER_SCHEDULE_CREATED; extra_data: null }
  | { action: InboxActionTypeEnum.USER_SCHEDULE_UPDATED; extra_data: null }
  | { action: InboxActionTypeEnum.USER_SCHEDULE_CANCELED; extra_data: null }
  | { action: InboxActionTypeEnum.RECOMMEND_INQUIRY; extra_data: InboxExtraDataMarketingSurvey }

export type InboxMessageRelatedExtraData =
  | { action: InboxActionTypeEnum.INQUIRY; extra_data: InboxExtraDataRelatedGig }
  | { action: InboxActionTypeEnum.RELATED_ORDER; extra_data: InboxExtraDataRelatedOrder }
  | { action: InboxActionTypeEnum.RELATED_PORTFOLIO; extra_data: InboxExtraDataRelatedPortfolio }
  | { action: InboxActionTypeEnum.RELATED_GIG; extra_data: InboxExtraDataRelatedGig }
  | { action: InboxActionTypeEnum.RELATED_CUSTOM_PROJECT; extra_data: InboxExtraDataRelatedCustomProject }
  | { action: InboxActionTypeEnum.RELATED_CUSTOM_PROJECT_ORDER; extra_data: InboxExtraDataRelatedCustomProjectOrder }

export type InboxMessageRequestExtraData =
  | { action: InboxActionTypeEnum.REQUEST; extra_data: InboxExtraDataRequest | null }
  | { action: InboxActionTypeEnum.REQUEST_CUSTOM_PROJECT; extra_data: InboxExtraDataRequestCustomProject }

export type InboxMessageScheduleExtraData =
  | { action: InboxActionTypeEnum.USER_SCHEDULE_CREATED; extra_data: InboxExtraDataUserScheduleCreated }
  | { action: InboxActionTypeEnum.USER_SCHEDULE_UPDATED; extra_data: InboxExtraDataUserScheduleUpdated }
  | { action: InboxActionTypeEnum.USER_SCHEDULE_CANCELED; extra_data: InboxExtraDataUserScheduleCanceled }

interface InboxExtraDataRelatedGig {
  PID: number;
  title: string;
  image: string;
  price: number;
  is_prime: boolean;
  category_info: {
    root_category_id: number;
    root_category_name: string;
    sub_category_id: number;
    sub_category_name: string;
    third_category_id: number | null;
    third_category_name: string | null;
  };
  is_module_category: boolean;
  /**
   * @deprecated 없앨 예정
   */
  gig_inquiry_answers: { id: number; value: string }[];
}

interface InboxExtraDataRelatedPortfolio {
  portfolio_id: number;
  username: string;
  title: string;
  portfolio_image: string;
}

interface InboxExtraDataRelatedOrder {
  title: string;
  image: string;
  OID: number;
  price: number;
  created_at: string;
  is_prime: boolean;
}

interface InboxExtraDataRelatedCustomProjectOrder {
  title: string;
  image: string;
  OID: number;
  price: number;
  created_at: string;
  is_prime?: boolean;
}

interface InboxExtraDataRelatedCustomProject {
  proposal_id: number;
  username: string;
  title: string;
  price: number;
  days: number;
  image: string;
}

export interface InboxExtraDataRequest {
  PID: number | null;
  title: string;
  content: string;
  price: number;
  days: number;
  image: string;
  is_prime: boolean;
  id?: number | null;
  OID?: number;
  MID?: number; // inbox message id
  proposal_id?: number;
  amount?: number;
  is_seller?: boolean;
  categoryId?: number | null;
  rootCategoryId?: number | null;
}

export interface InboxExtraDataRequestCustomProject {
  proposal_id: number;
  username: string;
  title: string;
  price: number;
  days: number;
  image: string;
}

interface InboxExtraDataMarketingSurvey {
  businessType: string | null; // 마케팅 추천 메시지
  planMoney: number; // 마케팅 추천 메시지
  surveyId: string | null; // 마케팅 추천 메시지
  username: string | null; // 마케팅 재구매 유도 쿠폰 회원명
}

interface InboxExtraDataUserScheduleBase {
  schedule_id: number;
  schedule_datetime: string;
}

interface InboxExtraDataUserScheduleCreated extends InboxExtraDataUserScheduleBase {
  title: '일정 확정';
}

interface InboxExtraDataUserScheduleUpdated extends InboxExtraDataUserScheduleBase {
  title: '일정 변경';
}

interface InboxExtraDataUserScheduleCanceled extends InboxExtraDataUserScheduleBase {
  title: '일정 취소';
}

export interface InboxFile {
  MID: number;
  download_url: string | null;
  FID: number;
  file_name: string | null;
  preview_url: string | null;
  created_at: string | null;
  is_available_to_download: boolean;
}

export interface InboxRequest {
  id: number | null;
  content: string;
  days: number;
  MID: number;
  PID: number | null;
  DOID: number | null; // direct order id
  OID: number | null;
  price: number;
}

interface InboxSender {
  USERID: number;
  username: string;
  image: string; // user thumbnail
}

export interface InboxWarningMessage {
  sender_warning_message: string | null;
  receiver_warning_message: string | null;
}

export interface GetInboxMessagesResponse {
  total: number;
  previous_page_link?: string | null;
  next_page_link?: string | null;
  last_page: number;
  messages: InboxMessage[];
}

export interface GetInboxMessagesParams {
  inbox_group_id: number;
  page?: number;
}

const getInboxMessages = async ({ inbox_group_id, page }: GetInboxMessagesParams) => {
  const { data } = await api.get<GetInboxMessagesResponse>(`/api/v5/inbox-groups/${inbox_group_id}/messages`, {
    params: {
      page,
    },
  });

  return data;
};

interface PostInboxMessagesResponse {
  inboxGroupId: number;
  payload: PostInboxMessagesPayload;
}

export interface InboxMessagesPayloadSearchParams {
  related_gig_id?: number;
  related_order_id?: number;
  related_portfolio_id?: number;
  related_custom_project_order_id?: number;
  related_custom_project_proposal_id?: number;
}

export interface PostInboxMessagesPayload extends InboxMessagesPayloadSearchParams {
  message: string;
  isEncryptedMessage: boolean;
  files?: PostInboxMessagesFile[];
  /**
   * @deprecated 마케팅 추천 문의하기 시 사용되는 필드. 지금은 미사용
   */
  filtered_keywords?: string[];
  index_id?: number;
  /**
   * @deprecated 앱 사용
   */
  socket_id?: string;
  warning_messages?: InboxWarningMessage;
}

export interface PostInboxMessagesFile {
  FID: number;
  name: string;
  preview_url: string;
}

const postInboxMessages = async ({
  inboxGroupId,
  payload,
}: PostInboxMessagesResponse) => {
  const { data } = await api.post<InboxMessage>(`/api/v5/inbox-groups/${inboxGroupId}/messages`, payload);

  return data;
};

export interface DeleteInboxRequestParams {
  inboxGroupId: number;
  messageId: number;
}

const deleteInboxRequest = async ({ inboxGroupId, messageId }: DeleteInboxRequestParams) => api.delete(`/api/v5/inbox-groups/${inboxGroupId}/messages/${messageId}/inbox-request`);

export interface GetInboxMessageTemplatesResponse {
  inboxMessageTemplates: InboxMessageTemplate[];
}

export interface InboxMessageTemplate {
  id: number;
  title: string;
  content: string;
  sort: number;
}

const getInboxMessageTemplates = async () => {
  const { data } = await api.get<GetInboxMessageTemplatesResponse>('/api/next/inbox-app/inbox/v1/inbox-message-templates');

  return data;
};

const deleteInboxMessageTemplate = async (inboxMessageTemplateId: number) => {
  const { data } = await api.delete<{ id: number }>(`/api/next/inbox-app/inbox/v1/inbox-message-templates/${inboxMessageTemplateId}`);

  return data;
};

export interface InboxMessageTemplatePayload {
  title: string;
  content: string;
}

const postInboxMessageTemplate = async (payload: InboxMessageTemplatePayload) => {
  const { data } = await api.post<InboxMessageTemplate>('/api/next/inbox-app/inbox/v1/inbox-message-templates', payload);

  return data;
};

const putInboxMessageTemplate = async (inboxMessageTemplateId: number, payload: InboxMessageTemplatePayload) => {
  const { data } = await api.put<{ id: number }>(`/api/next/inbox-app/inbox/v1/inbox-message-templates/${inboxMessageTemplateId}`, payload);

  return data;
};

interface PutInboxMessageTemplatesSortPayload {
  inboxMessageTemplates: InboxMessageTemplateSort[];
}

export type InboxMessageTemplateSort = Pick<InboxMessageTemplate, 'id' | 'sort'>;

const putInboxMessageTemplatesSort = async (payload: PutInboxMessageTemplatesSortPayload) => {
  const { data } = await api.put<{ ids: number[] }>('/api/next/inbox-app/inbox/v1/inbox-message-templates/sort', payload);

  return data;
};

export const messagesApi = {
  getInboxMessages,
  postInboxMessages,
  deleteInboxRequest,
  getInboxMessageTemplates,
  deleteInboxMessageTemplate,
  postInboxMessageTemplate,
  putInboxMessageTemplate,
  putInboxMessageTemplatesSort,
};
