import api from '../api';
import type { ProjectType } from '../customProject';
import type { GradeType } from '../gig';
import type { PortfolioCardBase } from '../portfolio';
import type { MeetingSchedule, MeetingStatus, ProposalStatus } from '../proposal';

export interface GetBasicResponse {
  USERID: number;
  avg_rating: number;
  count_rating: number;
  grade: GradeType;
  profilepicture: string;
  username: string;
  online: boolean;
  is_certificated: boolean;
  is_recommended_seller: boolean;
  vacation?: Vacation;
  contactable?: {
    contactable_status: string;
    description: string;
  };
}

/**
 * @description 프로필 기본 정보 호출 API
 */
const getBasic = async (id: number, requestId?: number) => {
  const { data } = await api.get<GetBasicResponse>(`/api/seller-profile/v2/${id}/basic${requestId ? `?request_id=${requestId}` : ''}`);

  return data;
};

export interface Portfolio {
  id: number;
  thumbnail: string;
}

interface PortfolioGig {
  gigId: number;
  portfolios: Portfolio[];
}

export interface GetSellerPortfolioGigResponse {
  items: PortfolioGig[];
}

/**
 * @description 다건 서비스 ID로 포트폴리오 목록 조회 API
 * @param gigIds 서비스 id들 (콤마 구분자 string)
 * @returns
 */
const getSellerPortfolioGig = async (gigIds: string) => {
  const { data } = await api.get<GetSellerPortfolioGigResponse>('/api/next/gig-app/seller-profile/portfolio/v1/portfolios', {
    params: {
      gigIds,
    },
  });

  return data;
};

export interface GetPortfolioParams {
  rootCategoryId?: number;
  page?: number;
  limit?: number;
}

export interface PortfolioStatusMap {
  WAITING: '승인대기';
  APPROVED_EDIT: '편집승인중';
  APPROVED: '노출중';
  REJECTED: '비승인';
}

export type PortfolioStatusKey = keyof PortfolioStatusMap;

export interface MyPortfolio {
  id: number;
  thumbnail: string;
  category: PortfolioDetailCategory;
  title: string;
  status: PortfolioStatusKey;
  authenticationMessage: string;
  bookmarkCount: number;
}

export interface GetMyPortfolioResponse {
  total: number;
  lastPage: number;
  portfolios: MyPortfolio[];
}

const getMyPortfolioList = async ({
  rootCategoryId = 0,
  page = 1,
  limit = 10,
}: GetPortfolioParams) => {
  const { data } = await api.get<GetMyPortfolioResponse>('/api/portfolio/v2/seller/portfolios', {
    params: {
      rootCategoryId,
      page,
      limit,
    },
  });

  return data;
};

export interface GetPortfolioListForBuyerParams extends GetPortfolioParams {
  USERID: number;
}

export type PortfolioListItem = Omit<PortfolioCardBase, 'seller'>;

interface GetPortfolioListForBuyerResponse {
  total: number;
  lastPage: number;
  portfolios: PortfolioCardBase[];
}

const getPortfolioListForBuyer = async ({
  USERID,
  rootCategoryId = 0,
  page = 1,
  limit = 10,
}: GetPortfolioListForBuyerParams) => {
  const { data } = await api.get<GetPortfolioListForBuyerResponse>(`/api/portfolio/v3/buyer/sellers/${USERID}/portfolios`, {
    params: {
      rootCategoryId,
      page,
      limit,
    },
  });

  return data;
};

interface PortfolioDetailAnswerItem {
  id: number;
  name: string;
}

export interface PortfolioDetailAnswer {
  formatId: number;
  formatTitle: string;
  items: PortfolioDetailAnswerItem[];
}

interface SubSkill {
  id: number;
  title: string;
  data: string[];
}

export interface SkillItem {
  parentId: number;
  parentTitle: string;
  subSkills: SubSkill[];
}

export interface PortfolioDetailFile {
  id: number;
  name: string;
  type: string;
  thumbnail: string;
  width: number | null;
  height: number | null;
}

interface Vacation {
  is_vacation: boolean;
  vacation_expired_at: string;
  vacation_reason: string;
}

export interface RelatedGig {
  gigId: number;
  title: string;
  thumbnail: string;
  price: number;
  reviewCount: number;
  reviewAverage: number;
  hasPackages: boolean;
  isBookmark: boolean;
}

export interface PortfolioDetailSeller {
  grade: GradeType;
  userId: number;
  nickname: string;
  thumbnail: string;
}

export interface PortfolioDetail {
  id: number;
  thumbnail: string;
  title: string;
  rootCategoryId: number;
  bookmarkCount: number;
  isCurrentUserBookmark: boolean;
  keywords: string[];
  skills: SkillItem[];
  client: string | null;
  description: string;
  endDate: string | null;
  startDate: string | null;
  answers: PortfolioDetailAnswer[];
  contentFiles: PortfolioDetailFile[];
  seller: PortfolioDetailSeller;
}

export interface PortfolioVacation {
  isVacation: boolean;
  vacationExpiredAt: string;
  vacationReason: string;
}

interface PortfolioDetailInfos {
  portfolio: PortfolioDetail;
  gigs: RelatedGig[];
  category: PortfolioDetailCategory;
  vacation: PortfolioVacation;
}

export interface PortfolioDetailCategory {
  rootCategoryId: number;
  rootCategoryName: string;
  subCategoryId: number;
  subCategoryName: string;
}

export interface PortfolioDetailResponse {
  portfolioDetail: PortfolioDetailInfos;
}

const getPortfolioDetail = async (id: number) => {
  const { data } = await api.get<PortfolioDetailResponse>(`/api/portfolio/v3/buyer/${id}`);

  return data;
};

const deletePortfolio = (id: number) => api.delete(`/api/portfolio/v1/seller/portfolios/${id}`);

const postBookmark = (id: number) => api.post(`/api/portfolio/v2/buyer/portfolios/${id}/bookmarks`);

const deleteBookmark = (id: number) => api.delete(`/api/portfolio/v2/buyer/portfolios/${id}/bookmarks`);

interface GetUserIdResponse {
  USERID: number;
}

const getUserId = async (username: string) => {
  const { data } = await api.get<GetUserIdResponse>(`/api/seller-profile/v2/${encodeURIComponent(username)}`);

  return data.USERID;
};

interface Category {
  root_category_id: number;
  root_category_name: string;
  count: number;
}

export interface GetPortfolioCountResponse {
  total: number;
  category_list: Category[];
}

const getPortfoliosCount = async () => {
  const { data } = await api.get<GetPortfolioCountResponse>('/api/portfolio/v1/seller/portfolios/count');

  return data;
};

interface PortfolioWithRootCategory {
  rootCategoryId: number;
  rootCategoryName: string;
  total: number;
  portfolios: PortfolioCardBase[];
}

export interface GetSellerPortfolioResponse {
  categoryPortfolios: PortfolioWithRootCategory[] | null;
}

const getSellerPortfoliosCount = async (USERID: number) => {
  const { data } = await api.get<GetSellerPortfolioResponse>(`/api/portfolio/v3/buyer/seller-profile/${USERID}`);

  return data;
};

interface CustomProject {
  requestId: number;
  title: string;
  status: string;
  mainImage: string;
  amount: number;
  days: number;
  projectType: ProjectType;
}

export interface SentProposal {
  customProject: CustomProject;
  id: number;
  content: string;
  amount: number;
  days: number;
  filterStatus: ProposalFilterStatus;
  sentAt: string;
  status: ProposalFilterStatus;
  expectedProfits: number;
  meeting: MeetingSchedule | null;
}

export interface GetSentProposalsResponse {
  totalCount: number;
  lastPage: number;
  availableProposalCount: number;
  unrepliedMeetings: number;
  proposals: SentProposal[];
}

export type ProposalFilterStatus = 'WAITING' | 'MATCHED' | 'RECANT' | 'CLOSED';

export interface GetSentProposalParams {
  page: number;
  filter: ProposalFilterStatus | 'ALL';
  meetingStatus: MeetingStatus | 'ALL';
  isNotRespond: boolean;
}

const getSentProposals = async (params: GetSentProposalParams) => {
  const { data } = await api.get<GetSentProposalsResponse>('/api/custom-project/v1/seller/proposals', { params });

  return data;
};

interface ChangeProposalActionStatusParams {
  proposalId: number;
  type: ProposalStatus;
}

const changeProposalActionStatus = async ({ proposalId, type }: ChangeProposalActionStatusParams) => {
  await api.patch(`/api/custom-project/v1/seller/proposal/${proposalId}/${type}`);
};

export interface GetSellerOtherPortfolioByPortfolioIdResponse {
  portfolios: PortfolioCardBase[];
}

const getSellerOtherPortfolioByPortfolioId = async (id: number) => {
  const { data } = await api.get<GetSellerOtherPortfolioByPortfolioIdResponse>(`/api/portfolio/v3/buyer/portfolios/${id}/others`);

  return data?.portfolios;
};

export interface AvailableTimeSettingsResponse {
  is_used: boolean;
}

const getAvailableTimeSettings = async () => {
  const { data } = await api.get<AvailableTimeSettingsResponse>('/api/v1/my-kmong/SELLER/available-time-settings');

  return data?.is_used ?? false;
};

const patchAvailableTimeSettings = async () => {
  try {
    const { data } = await api.patch<AvailableTimeSettingsResponse>('/api/v1/my-kmong/SELLER/available-time-settings');

    return data?.is_used;
  } catch (e) {
    return false;
  }
};

type SellerContactableStatus = 'NO_SETTING' | 'ACCESSIBLE';

interface Contactable {
  contactable_status: SellerContactableStatus;
  description: string | null;
}

interface GetSellerContactableStatusSettingsResponse {
  current_contactable: Contactable;
  index: number;
  metadata: Contactable[];
}

interface ContactableStatusError {
  errors: ContactableStatusAccessDenied[];
}

interface ContactableStatusAccessDenied {
  code: number;
  message: string;
}

const getSellerContactableStatusSettings = async () => {
  try {
    const { data } = await api.get<GetSellerContactableStatusSettingsResponse | ContactableStatusError>('/api/seller-profile/v2/seller-contactable-status-metadata');

    if ('current_contactable' in data) {
      return data?.current_contactable.contactable_status === 'ACCESSIBLE' ?? false;
    }

    return undefined;
  } catch (e) {
    return undefined;
  }
};

interface PutSellerContactableStatusSettingsResponse {
  contactable_status: SellerContactableStatus;
}

const putSellerContactableStatusSettings = async (param: boolean) => {
  const status: SellerContactableStatus = param ? 'ACCESSIBLE' : 'NO_SETTING';
  const { data } = await api.put<PutSellerContactableStatusSettingsResponse>('/api/seller-profile/v2/seller-contactable-status', {
    contactable_status: status,
  });

  return data?.contactable_status;
};

export interface SellerCompanyInformation {
  companyName: string;
  representativeName: string;
  address: string;
  contactNumber: string;
  companyLicenseNumber: string;
  commerceRegistrationNumber: string | null;
}

export interface SellerIndividualInformation {
  nickname: string;
  realName: string;
  contactNumber: string;
}

interface CompanySellerResponse {
  isCompanyUser: true;
  information: SellerCompanyInformation;
}

interface IndividualSellerResponse {
  isCompanyUser: false;
  information: SellerIndividualInformation;
}

type GetSellerInformationResponse = CompanySellerResponse | IndividualSellerResponse;

const getSellerInformation = async (sellerUserId: number) => {
  const { data } = await api.get<GetSellerInformationResponse>(`/api/seller/v1/${sellerUserId}/information`);

  return data;
};

export const sellerApi = {
  getBasic,
  getMyPortfolioList,
  getPortfolioListForBuyer,
  postBookmark,
  deleteBookmark,
  getPortfolioDetail,
  getUserId,
  deletePortfolio,
  getPortfoliosCount,
  getSellerPortfoliosCount,
  changeProposalActionStatus,
  getSellerOtherPortfolioByPortfolioId,
  getAvailableTimeSettings,
  patchAvailableTimeSettings,
  getSellerContactableStatusSettings,
  putSellerContactableStatusSettings,
  getSentProposals,
  getSellerInformation,
  getSellerPortfolioGig,
};
