import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';

export interface AvailableValue {
  id: number;
  value: string;
  isSelected: boolean;
}

export interface LifeStyleTypesQuestion {
  id: number;
  inputType: 'TEXT' | 'RADIO' | 'MULTI_SELECT';
  dataType: string;
  title: string;
  inquiryTitle: string;
  placeholder: string;
  isMandatory: boolean;
  value: string;
  hasError: boolean;
  availableValues?: AvailableValue[];
  image?: string;
  content?: string;
}

export interface PostLifeStyleTypesInquiresData {
  questions: LifeStyleTypesQuestion[];
}

interface OpenGraph {
  alt: string;
  image: string;
  title: string;
  description: string;
}

interface PersonalInformationTerms {
  title: string;
  content: string;
}

export interface GetLifeStyleTypesInquiresData {
  og: OpenGraph;
  bannerImage: string;
  title: string;
  description: string;
  questions: LifeStyleTypesQuestion[];
  personalInformationTerms: PersonalInformationTerms[];
}

export const lifeStyleTypes = {
  AIR_CONDITIONER_CLEANING: 'air-conditioner-cleaning',
  AIR_CONDITIONER_INSTALL: 'air-conditioner-installment',
  HOUSE_CLEANING: 'house-cleaning',
  PEST_CONTROL: 'pest-control',
};

const lifeStyleTypeEnum: Record<string, keyof typeof lifeStyleTypes> = {
  'air-conditioner-cleaning': 'AIR_CONDITIONER_CLEANING',
  'air-conditioner-installment': 'AIR_CONDITIONER_INSTALL',
  'house-cleaning': 'HOUSE_CLEANING',
  'pest-control': 'PEST_CONTROL',
};

export const getApplyFormQuestions = async (type: string): Promise<GetLifeStyleTypesInquiresData> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/static-json/apply-form/${type}.json`);

  return data.json();
};

export const postAirConditionerInquires = async (type: string, data: PostLifeStyleTypesInquiresData) => {
  await api.post(`/api/event/v1/life-style-types/${lifeStyleTypeEnum[type]}/inquiries`, data);
};
