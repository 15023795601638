import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { TempGig } from '../gig';

export interface CurationData {
  image: string;
  title: string;
  link: string;
  tags?: string[];
}

export interface CurationSectionData {
  title: string;
  subTitle?: string;
  link?: string;
  items: CurationData[];
}

export type KnowhowContentType = '' | 'EBOOK' | 'VOD' | 'LECTURE' | 'JOB' | 'TEMPLATE';

export interface PurchaseProperty {
  PID: number;
  title: string;
  image: string;
  remaining_days: number;
  OID: number;
}

export interface PurchaseFile {
  FID: number;
  extension: string;
}

export interface PurchaseVODGig extends PurchaseProperty {
  type: 'VOD';
  vod_id: number;
}

export interface PurchaseEBookGig extends PurchaseProperty {
  type: 'EBOOK';
  file: PurchaseFile;
}

export interface PurchaseContentGig extends PurchaseProperty {
  type: 'LECTURE' | 'JOB';
  seller: {
    userid: number;
    username: string;
  };
}

export interface PurchaseTemplateGig extends PurchaseProperty {
  type: 'TEMPLATE';
  file: PurchaseFile;
}

export type PurchaseGig =
  | PurchaseEBookGig
  | PurchaseVODGig
  | PurchaseContentGig
  | PurchaseTemplateGig;

export interface RecommendationGig {
  PID: number;
  title: string;
  image: string;
}

export interface GetBuyerContentsResponse {
  purchase_gigs: PurchaseGig[];
  recommendation_gigs: RecommendationGig[];
}

const getBuyerContents = async (type: KnowhowContentType) => {
  const { data } = await api.get<GetBuyerContentsResponse>(`/api/knowhow/v1/main/buyer-contents/${type}`);

  return data;
};

export interface GetBuyerContentsTypesResponse {
  types: KnowhowContentType[];
}

const getBuyerContentsTypes = async () => {
  const { data } = await api.get<GetBuyerContentsTypesResponse>('/api/knowHow/v1/main/buyer-contents/types');

  return data;
};

type CurationType = 'EVENT' | 'GIG';

export interface ViewMoreInfo {
  title: string;
  link: string;
}

export interface CurationEvent {
  title: string;
  keywords: string[];
  imageUrl: string;
  landingLink: string;
}

export interface CurationGig {
  slidesToShowCount: number;
  gigs: TempGig[];
}

export interface Curation {
  type: CurationType;
  title: string;
  subTitle?: string;
  viewMoreInfo?: ViewMoreInfo;
  events: CurationEvent[];
  gig?: CurationGig;
}

export interface GetCurationsResponse {
  curations: Curation[];
}

const getCurations = async () => {
  const { data } = await api.get<GetCurationsResponse>('/api/next/display-app/knowhow/curation/v1/curations');

  return data;
};

// https://api.dev.kmong.run/display-app/swagger-ui/

type KnowhowHeroBannerType = 'HERO_MAIN' | 'HERO_SUB';

type KnowhowBannerType = KnowhowHeroBannerType | 'ICON';

type KnowhowBannerPosition = 'HERO' | 'ICON';

export interface KnowhowBannerSection<T extends KnowhowBannerType, P extends KnowhowBannerPosition> {
  id: number;
  type: T;
  position: P;
  items: KnowhowBannerItem[];
}
export interface KnowhowBannerItem {
  id: number;
  title: string;
  link: string;
  imageUrl: string;
}

export interface KnowhowBannerExtraItem extends KnowhowBannerItem {
  extraData: {
    target: '_blank' | '_self';
  };
}

export type HeroBannerSection = KnowhowBannerSection<'HERO_MAIN' | 'HERO_SUB', 'HERO'>;

export type BannerIconSection = KnowhowBannerSection<'ICON', 'ICON'>;

export interface GetHeroBannersResponse {
  banners: HeroBannerSection[];
}

const getHeroBanners = async (customUA?: string) => {
  const { data } = await api.get<GetHeroBannersResponse>('/api/next/display-app/knowhow/banner/v1/banners/hero', customUA ? {
    headers: {
      'User-Agent': customUA,
    },
  } : undefined);

  return data;
};

export interface GetIconBannersResponse {
  banners: BannerIconSection[];
}

const getIconBanners = async () => {
  const { data } = await api.get<GetIconBannersResponse>('/api/next/display-app/knowhow/banner/v1/banners/icon');

  return data;
};

export interface GetPersonalizationResponse {
  gigs: TempGig[];
}

const getPersonalization = async () => {
  const { data } = await api.get<GetPersonalizationResponse>('/api/personalization/v1/similar-user-gigs');

  return data;
};

const getPersonalizationByGigId = async (gigId: number) => {
  const { data } = await api.get<GetPersonalizationResponse>(`api/personalization/v1/gigs/${gigId}/similar-gigs`);

  return data;
};

const getKnowhowSearchPlaceholders = async (): Promise<string[]> => {
  const response = await fetch(`${CLOUDFRONT_URL}/assets/knowhow/placeholders.json`);
  const data = await response.json();

  return data?.placeholders ?? [];
};
const knowhowMainApi = {
  getBuyerContents,
  getBuyerContentsTypes,
  getCurations,
  getHeroBanners,
  getIconBanners,
  getPersonalization,
  getPersonalizationByGigId,
  getKnowhowSearchPlaceholders,
};

export default knowhowMainApi;
