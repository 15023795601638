/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, Typography } from '@kmong/ui';
import { Link as GatsbyLink } from 'gatsby';

const FooterBlock = styled.footer`
  border-top: 1px solid ${colors.gray[200]};
`;

const Main = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 32px 16px;
  margin: 0 auto;
`;

const LinksWrapper = styled.div`
  margin-top: 32px;
  font-size: 14px;

  a {
    color: ${colors.denim[500]};
    margin-right: 16px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: ${colors.denim[500]};
    }
  }
`;

function Footer() {
  return (
    <FooterBlock>
      <Main>
        <Typography
          css={css`
            margin-bottom: 16px;
            font-weight: bold;
          `}
        >
          (주)크몽
        </Typography>
        <Typography
          color="gray600"
          variant="body2"
        >
          사업자 등록번호: 613-81-65278 | 대표: 박현호, 김태헌
        </Typography>
        <Typography
          color="gray600"
          variant="body2"
        >
          서울시 서초구 사임당로 157, 3층
        </Typography>
        <LinksWrapper>
          <GatsbyLink to="/notice">공지사항</GatsbyLink>
          <StyledLink href="https://support.kmong.com/hc/ko/categories/360001764852">약관 및 정책</StyledLink>
          <StyledLink href="https://support.kmong.com/hc/ko/articles/36149296352921 ">개인정보 처리방침</StyledLink>
        </LinksWrapper>
      </Main>
    </FooterBlock>
  );
}

const StyledLink = styled.a`
  color: ${colors.denim[500]};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default Footer;
