const getFavicon = () => {
  switch (process.env.NEXT_PUBLIC_MODE) {
    case 'local': {
      return 'favicon-local-32x32.png';
    }
    case 'development': {
      return 'favicon-dev-32x32.png';
    }
    default: {
      return 'favicon-32x32.png';
    }
  }
};

export default getFavicon;
