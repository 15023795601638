import { CLOUDFRONT_URL } from '@kmong-service/utils';

export const defaultKeywords = {
  title: '전문가가 필요한 순간, 프리랜서 마켓 No.1 크몽',
  description: '마케팅·디자인·IT프로그래밍·영상 등 다양한 비즈니스 분야의 전문가를 만나보세요!',
  ogImage: {
    url: `${CLOUDFRONT_URL}/assets/images/desktop/og_img.jpg`,
    alt: '전문가가 필요한 순간, 프리랜서 마켓 No.1 크몽',
    width: 1200,
    height: 630,
  },
  organizationDescription: '마케팅·디자인·IT프로그래밍·영상 등 다양한 비즈니스 분야의 전문가를 만나보세요!',
};
