import { nl2br } from '@kmong/utils';
import type { SEOProps } from '../..';

export const enterpriseFaqData = [{
  summary: '크몽 엔터프라이즈는 크몽 마켓 서비스와 무엇이 다른가요?',
  details: `<b>크몽 마켓</b>:  전문가 서비스를 의뢰인이 직접 검색한 후 거래하며, 빠르고 편리한 중/저가 서비스 구매에 적합합니다.
  <b>크몽 엔터프라이즈</b>: 의뢰인이 작성한 요청 사항을 기반으로 전담 매니저가 전문가를 추천하며, 상담/논의가 필요한 고가 프로젝트, 정부 지원 자금 활용 등에 적합합니다.`,
}, {
  summary: '크몽 전담 매니저는 어떻게 배정되나요?',
  details: '등록된 프로젝트 내용을 크몽이 검토한 후, 전담 매니저 배정 여부를 결정하여 알림 및 유선을 통해 알려드립니다.',
}, {
  summary: '모든 프로젝트에 전담 매니저가 배정되나요?',
  details: '네, 엔터프라이즈에서 진행이 가능한 모든 프로젝트는 전담 매니저가 배정됩니다.',
}, {
  summary: '정부지원사업에 선정되었는데, 크몽에서 외주 진행 가능한가요?',
  details: `네 가능합니다. 정부지원사업 전담 매니저가 지원 사업별 요구사항에 맞는 계약 / 결제 방식을 제시해드립니다.
  또한 행정절차 지원, 하나의 계약에 여러 분야의 전문가 통합 투입 등 계약 체결 전 과정과 과업 마무리 단계까지 지원해드립니다.`,
}, {
  summary: '크몽 엔터프라이즈는 별도 이용 요금이 있나요?',
  details: `<b>[외주]</b>
  고객사는 상담, 전문가 추천, 계약 등 전 과정을 무료로 이용할 수 있습니다.

  <b>[상주]</b>
  상주 프로젝트(지정된 장소에서 기간제 업무) 진행 시, 계약 총액의 10%(VAT 별도)가 고객사에게 이용료로 부과됩니다.
  이용료는 프로젝트를 실제로 진행하는 경우에만 발생하며, 계약 전까지는 발생하지 않습니다.
  커스텀 리쿠르팅 진행 시에는 담당 매니저 상담 후 별도 수수료가 적용될 수 있습니다.`,
}];

export const enterpriseFaqJsonLd = `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    ${enterpriseFaqData.map((faqItem) => `{
      "@type": "Question",
      "name": "${faqItem.summary}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${nl2br(faqItem.details)}"
      }
    }`)}
   ]
}`;

const title = '크몽 엔터프라이즈 - 기업 전용 아웃소싱 솔루션';

export const enterpriseSEO: SEOProps = {
  title,
  description: '분야별 전문 매니저 상담, 국내 최대 전문가 풀, 계약 완수율 99%, 빠르고 안전한 전문가 매칭 서비스를 경험해보세요!',
  openGraph: {
    image: {
      url: 'https://d2v80xjmx68n4w.cloudfront.net/press/og/og_enterprise.png',
      alt: title,
    },
  },
  breadcrumbItemList: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      item: 'https://kmong.com/enterprise',
      name: '엔터프라이즈',
      position: 2,
    },
  ],
};
